import store from '@/store'

export default [
  {
    header: 'Contracts',
    resource: 'contracts_closed' || 'contracts_tia' || 'contracts_active' || 'contracts_lapsed' || 'contracts_renew',
    action: 'read',
  },

  /*
  *
  *  Menu Items
  * */
  {
    title: 'Closed',
    route: 'contracts_closed',
    icon: 'BriefcaseIcon',
    resource: 'contracts_closed',
    action: 'read',
    tag: store.getters['contracts/closedContractsList'].length,
  },
  {
    title: 'TIA',
    route: 'contracts_tia',
    icon: 'AwardIcon',
    resource: 'contracts_tia',
    action: 'read',
    tag: store.getters['contracts/tiaContractsList'].length,
  },
  {
    title: 'Active',
    route: 'contracts_active',
    icon: 'CheckCircleIcon',
    resource: 'contracts_active',
    action: 'read',
    tag: store.getters['contracts/activeContractsList'].length,
  },
  {
    title: 'Lapsed',
    route: 'contracts_lapsed',
    icon: 'SlashIcon',
    resource: 'contracts_lapsed',
    action: 'read',
    tag: store.getters['contracts/lapsedContractsList'].length,
  },
  {
    title: 'Need Renewal',
    route: 'contracts_renew',
    icon: 'ArrowRightIcon',
    resource: 'contracts_renew',
    action: 'read',
  },
]
